html, body {
    margin: 0;
    padding: 0;
    height: 100%; /* Ensure full height */
}

body {
    font-family: sans-serif;
    user-select: none;
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Space between top and bottom elements */
}

#app {
    font-size: 72px;
    text-align: center;
    margin-top: 0; /* Remove top margin */
}

.sum-display {
    font-size: 20px;
    color: gray;
    text-align: center;
    margin-bottom: 0; /* Remove bottom margin */
}
